import React from "react";

const ThreeBees = ({ color }) => (
  <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M86.9733 132.036C99.492 128.682 106.944 115.9 103.618 103.486C100.292 91.073 87.447 83.7292 74.9283 87.0836C62.4096 90.438 54.9576 103.22 58.2838 115.634C61.6099 128.047 74.4547 135.391 86.9733 132.036Z" fill="#333333" />
    <path d="M101.886 117.211C112.128 114.466 118.225 104.008 115.504 93.8515C112.783 83.6951 102.273 77.6866 92.0306 80.4311C81.7881 83.1755 75.691 93.6338 78.4124 103.79C81.1338 113.947 91.6431 119.955 101.886 117.211Z" fill="#FFB000" />
    <path d="M106.994 67.6613C119.513 64.3069 126.965 51.5247 123.638 39.1113C120.312 26.698 107.467 19.3542 94.9488 22.7086C82.4301 26.063 74.9781 38.8452 78.3043 51.2586C81.6304 63.6719 94.4752 71.0157 106.994 67.6613Z" fill="#333333" />
    <path d="M121.906 52.8355C132.149 50.0911 138.246 39.6329 135.524 29.4765C132.803 19.3201 122.294 13.3116 112.051 16.0561C101.809 18.8005 95.7115 29.2588 98.4329 39.4151C101.154 49.5715 111.664 55.58 121.906 52.8355Z" fill="#F76E9C" />
    <path d="M41.3112 85.2609C53.8299 81.9066 61.2819 69.1243 57.9558 56.7109C54.6296 44.2976 41.7848 36.9538 29.2662 40.3082C16.7475 43.6626 9.2955 56.4448 12.6216 68.8582C15.9478 81.2715 28.7925 88.6153 41.3112 85.2609Z" fill="#333333" />
    <path d="M56.2236 70.4352C66.4661 67.6907 72.5632 57.2325 69.8418 47.0761C67.1204 36.9197 56.6111 30.9112 46.3685 33.6557C36.126 36.4002 30.0289 46.8584 32.7503 57.0147C35.4717 67.1711 45.981 73.1796 56.2236 70.4352Z" fill="#73A77B" />
  </svg>

);

export default ThreeBees;
